module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Oswald:300,500,700","Roboto:400,500,700,900","Open Sans:300,400,600,700","Open Sans Pro:300,400,600,700","Montserrat:400,500,600,700,800,900","Poppins: 400","Nunito: 700,"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MQBM5X2","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../plugins/ropto-pixels/gatsby-browser.js'),
      options: {"plugins":[],"development":false},
    },{
      plugin: require('../node_modules/@ropto/ropto-session-gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[],"enablePosts":false,"baseUrl":"https://sessions.ropto.com","pageDetails":{"pageId":"655591","pageName":"LP010","brand":"The Claims Guys","pageVariant":"V001"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
